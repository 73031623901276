<template>
    <b-sidebar  v-model="show" backdrop backdrop-variant="light" width="531px" bg-variant="white" right no-header-close>
        <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-variant="primary"
            >
            <div class="p-24 mt-5 mt-md-2">
                <div class="d-flex">
                    <app-heading-3>User Details</app-heading-3>
                    <feather-icon icon="XIcon" size="20" class="cursor-pointer ml-auto d-md-none"  @click="show = false"></feather-icon>
                </div>

                <div class="d-flex align-items-center">
                    <div class="mr-2 mt-2">
                        <img v-if="user_data.profilePicture" :src="user_data.profilePicture" class="rounded-circle" width="100" height="100" alt="">
                        <div v-else class="profile-avatar d-flex justify-content-center align-items-center">
                            <feather-icon class="" icon="UserIcon" size="32" color="#004bff"/>
                        </div>
                    </div>
                    <div>
                        <app-heading-2>{{user_data.first_name}} {{user_data.last_name}}</app-heading-2>
                        <app-heading-4>{{toCapitalize(user_data.role)}}</app-heading-4>
                    </div>
                   
                </div>
            </div>
            <div class="p-24">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <app-heading-4 class="d-flex align-items-center mb-12">First Name: <app-normal-text class="ml-8">{{user_data.first_name}}</app-normal-text></app-heading-4>
                        <app-heading-4 class="d-flex align-items-center mb-12">Email: <app-normal-text class="ml-8">{{user_data.email.length>20?user_data.email.slice(0,20)+'...':user_data.email}}</app-normal-text></app-heading-4>
                        <app-heading-4 class="d-flex align-items-center mb-12">Subscription: <app-normal-text class="ml-8">{{user_data.subscription}}</app-normal-text></app-heading-4>  
                    </div>
                    <div class="col-md-6 col-12">
                        <app-heading-4 class="d-flex align-items-center mb-12">Last Name: <app-normal-text class="ml-8">{{user_data.last_name}}</app-normal-text></app-heading-4>
                        <app-heading-4 class="d-flex align-items-center mb-12">Phone Number: <app-normal-text class="ml-8">{{user_data.phoneNumber}}</app-normal-text></app-heading-4>
                        <app-heading-4 class="d-flex align-items-center">Role: <app-normal-text class="ml-8">{{toCapitalize(user_data.role)}}</app-normal-text></app-heading-4>
                    </div>
                </div>
            </div>
            <div class="p-24 border-0">
                <div class="d-flex">
                    <div class="w-50">
                        <app-heading-4 class="d-flex align-items-center mb-12">Documents Sent: <app-normal-text class="ml-8">{{user_data.documentsSent}}</app-normal-text></app-heading-4>
                        <app-heading-4 class="d-flex align-items-center mb-12">Document Pending: <app-normal-text class="ml-8">{{user_data.documentsPending}}</app-normal-text></app-heading-4>
                        <app-heading-4 class="d-flex align-items-center">Support Tickets: <app-normal-text class="ml-8">{{user_data.supportQueriesCount}}</app-normal-text></app-heading-4>
                    </div>
                    <div class="w-50">
                        <app-heading-4 class="d-flex align-items-center mb-12">Documents Recieved: <app-normal-text class="ml-8">{{user_data.documentsReceived}}</app-normal-text></app-heading-4>
                    </div>
                </div>
            </div>
        </b-overlay>
        <div class="d-flex p-24 border-0 button-bottom w-100">
            <app-simple-button variant="primary" @click="editUser()">Edit User</app-simple-button>
            <app-simple-button variant="danger" class="ml-8" @click="deleteUser()">Delete User</app-simple-button>
        </div>
    </b-sidebar>
</template>
<script>
import AppHeading3 from '@/components/app-components/AppHeading3.vue'
import AppHeading2 from '@/components/app-components/AppHeading2.vue'
import AppHeading4 from '@/components/app-components/AppHeading4.vue'
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import {get_user_info} from '@/apis/admin/users'
import {BSidebar, BOverlay} from 'bootstrap-vue'
export default {
    components: {
        AppHeading3,
        BSidebar,
        AppHeading2,
        AppHeading4,
        AppNormalText,
        AppSimpleButton,
        BOverlay
    },
    data(){
        return{
            show:false,
            user_data:{
                documentsReceived: 0,
                documentsSent: 0,
                documentsPending:0,
                email: "",
                first_name: "",
                last_name: "",
                phoneNumber: "",
                role: "",
                subscription: "",
                supportQueriesCount: 0,
                profilePicture:''
            },
            userDetails:null,
            loading:false
        }
    },
    methods:{
       showDetails(details){
            this.userDetails=details;
            this.show=true;
            this.getUserInfo(details._id)
       },
       getUserInfo(id){
        this.loading=true;
        get_user_info(id).then(resp=>{
            this.loading=false;
            this.user_data=resp.data;
        }).catch(err=>{
            this.loading=false;
        })
       },
       editUser(){
        this.show=false
        this.$emit('onEdit', this.userDetails)
       },
       deleteUser(){
        this.show=false
        this.$emit('onDelete', this.userDetails._id)
       },
       toCapitalize(name){
        let words = name.split("_")
            let upperCaseStr= words.map(w=>`${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(" ")
            return upperCaseStr
        }, 
    }
}
</script>
<style >
.p-24{
    padding: 24px;
    border-bottom: 1px solid #DBDADE;
}
.ml-8{
    margin-left: 8px;
}
.mb-12{
    margin-bottom: 12px;
}
.button-bottom{
    position: absolute;
    bottom: 0;
}
.profile-avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e0e9ff;
}
@media(max-width:425px){
    .b-sidebar-outer{
        z-index: 10 !important;
    }
}
</style>