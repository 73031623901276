<template>
    <div>
        <app-paginated-table ref="paymentsList" :fields="fields" :items="items" :mobile-headers="mobile" :current-page="current_page" :total-pages="total_pages" :no-filters="true" :total="total_records" :loading="loading" @onPageChange="onPageChange" :mobileHeaders="['invoice_no','amount','release','actions']" :placeRight="3"  @onLoadMore="loadMorePayments">
            <template #header>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12">
                        <app-text-input v-model="filters.search" placeholder="Search" @input="getPaymentsList()" class="m-8-24"></app-text-input>
                    </div>
                </div>
            </template>
            <template #filters>
                <div class="row">
                    <div class="col-4">
                        <app-text-input v-model="filters.search" placeholder="Search" @input="getPaymentsList()"></app-text-input>
                    </div>
                </div>
            </template>
            <template #head(actions)>
                {{ '' }}
                <!-- <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                /> -->
            </template>
            <template #head(release)>
                {{ '' }}
                <!-- <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                /> -->
            </template>
            <template #cell(invoice_no)="data">
                {{data.item.invoice? data.item.invoice.invoiceNo:'N/A'}}
            </template>
            <template #cell(sender)="data">
                {{ data.item.sender.first_name}} {{ data.item.sender.last_name}}
            </template>
            <template #cell(recipient)="data">
                {{ data.item.receiver.first_name}} {{ data.item.receiver.last_name}}
            </template>
            <template #cell(date)="data">
                {{ getFormatedDate(data.item.date,"DD MMM YYYY") }}
            </template>
            <template #cell(amount)="data">
                {{ data.item.amount | currency}}
            </template>
            <template #cell(release)="data">
                <div :class="[(data.item.paymentType!='remaining'||disableBtn)?'disabled-btn':'']" @click="releasePayments(data.item)">
                    <img :src="require('@/assets/images/app-icons/payment_release.png')" alt="">
                </div>
            </template>
            <template #cell(actions)="data">
                <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Block Level Dropdown Menu"
                right
                variant="none"
                menu-class="w-100"
                size="sm"
                no-caret
                >
                <template #button-content>
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="18"
                    />
                </template>
                <b-dropdown-item :disabled="data.item.paymentType!='initial'" @click="refundPayment(data.item)">Refund</b-dropdown-item>
                </b-dropdown>
                
            </template>
            <template #cell(status)="data">
                <b-badge :variant="data.item.paymentType=='initial'?'light-warning':data.item.paymentType=='remaining'?'light-primary':data.item.paymentType=='final'?'light-success':'light-danger'">
                    {{ data.item.paymentType=='initial'?'Partialy Paid':data.item.paymentType=='remaining'?'Paid':data.item.paymentType=='final'?'Released':'Refunded' }}
                </b-badge>
            </template>
            <template>
            </template>
        </app-paginated-table>
    </div>
</template>
<script>
import {BTabs,BTab,BCard,BTableLite, BDropdown,BBadge, BDropdownItem,BAvatarGroup, BAvatar} from 'bootstrap-vue'
import AppPaginatedTable from '@/components/app-components/AppPaginatedTable.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppSelectBox from '@/components/app-components/AppSelectBox.vue'
import AppConfirmModal from '@/components/app-components/AppConfirmModal.vue'
import UpdateUser from '@/components/functional-components/admin/users/UpdateUser.vue'
import UserDetails from '@/components/functional-components/admin/users/UserDetails.vue'
import {get_payments_list,release_payment,refund_payment} from '@/apis/admin/payments'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
export default{
    directives:{
        Ripple
    },
    components:{
        BTabs,
        BTab,
        BCard,
        BTableLite,
        AppPaginatedTable,
        AppTextInput,
        BDropdown,
        BDropdownItem,
        AppSelectBox,
        AppConfirmModal,
        UpdateUser,
        UserDetails,
        BAvatarGroup,
        BAvatar,
        BBadge
    },
    data(){
        return{
            search:'',
            status_filter:{
                active:false,
                disabled:false  
            },
            fields:[
                {
                    key:'invoice_no',
                    label:'Invoice #'
                },
                {
                    key:'amount',
                    label:'Amount'
                },
                {
                    key:'sender',
                    label:'Sender'
                },
                {
                    key:'recipient',
                    label:'Recipient'
                },
                {
                    key:'status',
                    label:'Status'
                },
                {
                    key:'date',
                    label:'Date'
                },
                {
                    key:'release',
                },
                {
                    key:'actions'
                }
            ],
            items: [
                {
                    invoice_no:'150dg505' ,
                    amount:'$306260',
                    sender:'trungkienspktnd@gamail.com',
                    recipient:'ckctm12@gmail.com',
                    paid:true,
                    date:'10 Mar 2022',
                },
                {
                    invoice_no:'150dg506' ,
                    amount:'$406260',
                    sender:'thuhang.nute@gmail.com',
                    recipient:'vuhaithuongnute@gmail.com',
                    paid:true,
                    date:'11 Mar 2022',
                },
                {
                    invoice_no:'150dg507' ,
                    amount:'$342260',
                    sender:'trungkienspktnd@gamail.com',
                    recipient:'ckctm12@gmail.com',
                    paid:true,
                    date:'12 Mar 2022',
                },
                {
                    invoice_no:'150dg508' ,
                    amount:'$606260',
                    sender:'thuhang.nute@gmail.com',
                    recipient:'vuhaithuongnute@gmail.com',
                    paid:false,
                    date:'13 Mar 2022',
                },
                {
                    invoice_no:'150dg509' ,
                    amount:'$301260',
                    sender:'trungkienspktnd@gamail.com',
                    recipient:'ckctm12@gmail.com',
                    paid:false,
                    date:'14 Mar 2022',
                },
            ],
            filters:{
                page:1,
                limit:10,
                search:'',
                status:''
            },
            current_page:1,
            total_records:5,
            total_pages:0,
            status:'',
            loading:false,
            delete_id:'',
            options: [
                { value: '', text: 'Status' },
                { value: 'paid', text: 'Paid' },
                { value: 'un_paid', text: 'Un Paid' },
            ],
            showSidebar:false,
            mobile:['invoice_no','status','actions'],
            disableBtn:false,
            loadMore:false,
        }
    },
    mounted(){
        this.getPaymentsList()
    },
    methods:{
        onPageChange(page){
            this.filters.page=page
            this.getPaymentsList()
        },
        getPaymentsList(){
            this.loading=true
            get_payments_list(this.filters).then(resp=>{
                this.loading=false
                this.items=this.loadMore?[...this.items,...resp.data.payments]:resp.data.payments
                this.current_page=Number(resp.data.currentPage)
                this.total_records=resp.data.paymentsCount
                this.total_pages=resp.data.paymentsCount>0?resp.data.paymentsCount/this.filters.limit:0
                this.$refs.paymentsList.loadMore=false
                this.loadMore=false
            }).catch(err=>{
                this.loading=false
            })
        },
        getFormatedDate(date,format){
            return moment(date).format(format)
        },
        releasePayments(data){
            let paymentData={
                amount:data.invoice.amount,
                invoice:`${data.invoice._id}`,
                receiver:`${data.receiver._id}`,
                paymentType:'final'
            }
            this.disableBtn=true
            release_payment(paymentData).then(resp=>{
                this.$bvToast.toast(resp.message, {
                    title: 'Payment Released',
                    variant: 'success',
                    solid: true,
                })
                this.getPaymentsList()
                this.disableBtn=false
            }).catch(err=>{
                this.$bvToast.toast(err.message, {
                    title: err.error,
                    variant: 'danger',
                    solid: true,
                })
                this.disableBtn=false
            })

        },
        refundPayment(data){
            let paymentData={
                paymentIntentId:data.stripePaymentId
            }
            refund_payment(paymentData).then(resp=>{
                this.$bvToast.toast(resp.message, {
                    title: 'Payment Refund',
                    variant:'success',
                    solid: true,
                })
                this.getPaymentsList()
            }).catch(err=>{
                this.$bvToast.toast(err.message, {
                    title: err.error,
                    variant: 'danger',
                    solid: true,
                })
            })
        },
        loadMorePayments(){
            this.loadMore=true
            this.filters.page+=1
            this.getPaymentsList()
        }
    }
}
</script>
<style scoped>
.m-8-24{
    margin:8px 24px !important
}
.disabled-btn{
    pointer-events: none;
    opacity: 0.5;
    cursor: default !important;
}
</style>